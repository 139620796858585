import React from "react";
import { ChevronBarLeft, ChevronBarRight } from "react-bootstrap-icons";

export const TableComponent = ({ tableInstance, onRowClick = (id) => {} }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
  } = tableInstance;

  return (
    <div className="overflow-x-auto">
      <table
        {...getTableProps()}
        className="min-w-full divide-y divide-gray-200 table-auto"
      >
        <thead className="">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())} // Habilita el ordenamiento
                  className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer"
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className="bg-white divide-y divide-gray-200"
        >
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => {
                  !!onRowClick && onRowClick(row.original?.id);
                }}
              >
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className="px-6 py-4 whitespace-nowrap text-sm"
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
          {page.length < 1 && (
            <tr>
              <td colspan={4} className="text-center text-sm pt-8 italic">
                Sin elementos para mostrar
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Controles de paginación */}
      <div className="flex justify-end gap-2 mt-4">
        <button
          title="Anterior"
          onClick={previousPage}
          disabled={!canPreviousPage}
          className={`px-4 py-2 border-black rounded ${
            !canPreviousPage ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          <ChevronBarLeft color="black" />
        </button>
        <button
          title="Siguiente"
          onClick={nextPage}
          disabled={!canNextPage}
          className={`px-4 py-2 border-black rounded ${
            !canNextPage ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          <ChevronBarRight color="black" />
        </button>
      </div>
    </div>
  );
};
