import axios from "axios";

const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/lider";

export const permisoApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  return {
    getPermisos: async ({ ...filterObj }) => {
      const filter = filterObj.filter;
      const {
        search = "",
        pagination = { page: 1, pageSize: 50 },
        ordering = "",
      } = filter;
      const { page, pageSize } = pagination;
      //
      try {
        const response = await axios.get(
          `${URL_BASE}/permiso/?search=${search}&page=${page}&page_size=${pageSize}&ordering=${ordering}`,
          { headers }
        );
        return response.data;
      } catch (error) {
        console.error("getPermisos error", error);
        throw error;
      }
    },

    getPermiso: async (id) => {
      try {
        const response = await axios.get(`${URL_BASE}/permiso/${id}/`, {
          headers,
        });
        return response.data;
      } catch (error) {
        console.error("getPermiso detail error", error);
        throw error;
      }
    },

    createPermiso: async (data) => {
      try {
        const formData = new FormData();

        // Assuming data contains both regular fields and files
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            // Check if the value is a File object or an array of files
            if (Array.isArray(data[key])) {
              data[key].forEach((file) => {
                formData.append(key, file); // Append files with the key
              });
            } else {
              formData.append(key, data[key]); // Append regular fields
            }
          }
        }

        const response = await axios.post(`${URL_BASE}/permiso/`, formData, {
          headers: {
            ...headers,
            // 'Content-Type': 'multipart/form-data', // Optional: can be removed
          },
        });
        return response.data;
      } catch (error) {
        console.error("create permiso error", error);
        throw error;
      }
    },

    updatePermiso: async (id, data) => {
      try {
        const formData = new FormData();

        // Assuming data contains both regular fields and files
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            // Check if the value is a File object or an array of files
            if (Array.isArray(data[key])) {
              data[key].forEach((file) => {
                formData.append(key, file); // Append files with the key
              });
            } else {
              formData.append(key, data[key]); // Append regular fields
            }
          }
        }

        const response = await axios.put(
          `${URL_BASE}/permiso/${id}/`,
          formData,
          {
            headers: {
              ...headers,
              // 'Content-Type': 'multipart/form-data', // Optional: can be removed
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error("update permiso error", error);
        throw error;
      }
    },

    deletePermiso: async (id) => {
      try {
        const response = await axios.delete(
          `${URL_BASE}/permiso/${id}/delete/`,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        console.error("delete permiso error", error);
        throw error;
      }
    },

    addComentarPermiso: async (permisoId, comentario) => {
      console.log("addComentarPermiso", permisoId, comentario);
      try {
        const response = await axios.post(
          `${URL_BASE}/permiso/${permisoId}/comentarios/`,
          { comentario: comentario },
          { headers }
        );
        return response.data;
      } catch (error) {
        console.error("Error adding comentario:", error);
        throw error;
      }
    },

    deleteComentarioPermiso: async (permisoId, comentarioId) => {
      try {
        const response = await axios.delete(
          `${URL_BASE}/permiso/${permisoId}/comentarios/${comentarioId}/`,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        console.error("delete permiso error", error);
        throw error;
      }
    },

    getSolicitudesPendientes: async (filterObj) => {
      const filter = filterObj.filter;
      const {
        search = "",
        pagination = { page: 1, pageSize: 50 },
        ordering = "",
      } = filter;
      const { page, pageSize } = pagination;
      try {
        const response = await axios.get(
          `${URL_BASE}/permiso/solicitudes_pendientes/?search=${search}&page=${page}&page_size=${pageSize}&ordering=${ordering}`,
          { headers }
        );
        return response.data;
      } catch (error) {
        console.error("getSolicitudesPendientes error", error);
        throw error;
      }
    },

    getSolicitudesHistorial: async (filterObj) => {
      const filter = filterObj.filter;
      const {
        search = "",
        pagination = { page: 1, pageSize: 50 },
        ordering = "",
      } = filter;
      const { page, pageSize } = pagination;
      try {
        const response = await axios.get(
          `${URL_BASE}/permiso/solicitudes_historial/?search=${search}&page=${page}&page_size=${pageSize}&ordering=${ordering}`,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        console.error("getSolicitudesHistorial error", error);
        throw error;
      }
    },

    aceptarPermiso: async (id) => {
      try {
        const response = await axios.post(
          `${URL_BASE}/permiso/${id}/aceptar/`,
          {},
          { headers }
        );
        return response.data;
      } catch (error) {
        console.error("aceptarPermiso error", error);
        throw error;
      }
    },

    rechazarPermiso: async (id) => {
      try {
        const response = await axios.post(
          `${URL_BASE}/permiso/${id}/rechazar/`,
          {},
          { headers }
        );
        return response.data;
      } catch (error) {
        console.error("rechazarPermiso error", error);
        throw error;
      }
    },

    getTiposPermiso: async () => {
      try {
        const response = await axios.get(`${URL_BASE}/permiso/tipo-permisos/`, {
          headers,
        });
        // );
        return response.data;
      } catch (error) {
        console.error("getTiposPermiso error", error);
        throw error;
      }
    },
  };
};
