import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Header from "src/modules/app/components/Header";
import backButton from "src/modules/common/components/images/back-arrow.png";
import { permisoActions } from "../handlers/redux";
import dayjs from "dayjs";
import { MultipleFileUpload } from "../components/MultipleFileUpload";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";

export function PermisoForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams(); // Get the ID from the URL for updates
  const tiposPermiso = useSelector((state) => state.permiso.tiposPermiso);
  const tiposPermiso_loading = useSelector(
    (state) => state.permiso.tiposPermiso_loading
  );
  const permisoData = useSelector((state) => state.permiso.permiso);
  const { es_editable = false } = permisoData;
  const permisos_loading = useSelector(
    (state) => state.permiso.permisos_loading
  );

  useEffect(() => {
    dispatch(permisoActions.getTiposPermiso()); // Fetch 'Tipo de permiso' options on component mount
    if (id) {
      dispatch(permisoActions.getPermiso(id)); // Fetch permiso data if updating
    } else {
      dispatch(permisoActions.setPermiso({}));
    }
  }, [dispatch, id]);

  const formik = useFormik({
    initialValues: {
      tipo: permisoData?.tipo || "",
      inicio: permisoData?.inicio || "",
      fin: permisoData?.fin || "",
      hora_inicio: permisoData?.hora_inicio || "",
      hora_fin: permisoData?.hora_fin || "",
      asunto: permisoData?.asunto || "",
      descripcion: permisoData?.descripcion || "",
      archivos: permisoData?.archivos || [],
    },
    enableReinitialize: true, // Allow form to reinitialize when permisoData changes
    onSubmit: (values) => {
      console.log("values", values);
      const { archivos } = values;
      if (archivos.length > 0) {
        if (archivos[0].id) {
          delete values.archivos;
        }
      }
      if (id) {
        dispatch(permisoActions.updatePermiso({ id: id, data: values })); // Update mode
      } else {
        dispatch(permisoActions.createPermiso(values)); // Create mode
      }
    },
  });

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    formik.setFieldValue("archivos", files);
  };

  const onBack = () => {
    return (
      <div
        onClick={() => navigate(-1)}
        className={"h-full flex items-center pr-2 z-10 cursor-pointer mr-2"}
      >
        <img className="back" src={backButton} alt="Regresar" />
      </div>
    );
  };
  useEffect(() => {
    if (tiposPermiso.length > 0 && permisoData.id) {
      const currentTipo = tiposPermiso.filter(
        (tipo) => tipo.nombre === permisoData.tipo
      );
      if (currentTipo[0]) formik.setFieldValue("tipo", currentTipo[0].id);
    }
  }, [tiposPermiso, permisoData]);

  if (!es_editable && id) {
    return;
  }

  return (
    <div>
      <Header
        titulo={`${id ? "Editar permiso" : "Solicitar Permiso"}`}
        className={`bg-white`}
        LeftComponent={onBack}
      />
      {!permisos_loading && !tiposPermiso_loading && (
        <form
          onSubmit={formik.handleSubmit}
          className="w-full md:w-2/3 px-2 mx-auto mt-4"
        >
          {/* First row: Tipo de permiso */}
          <div className="mb-4">
            <label className="block text-gray-700">Tipo de permiso</label>
            <select
              name="tipo"
              value={formik.values.tipo}
              onChange={formik.handleChange}
              className="w-full p-2 border border-gray-300 rounded"
            >
              <option value="">Seleccione un tipo</option>
              {tiposPermiso?.map((tipo) => (
                <option key={tipo.id} value={tipo.id}>
                  {tipo.nombre}
                </option>
              ))}
            </select>
          </div>

          {/* Second row: inicio and fin */}
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-gray-700">Inicio</label>
              <input
                type="date"
                name="inicio"
                value={formik.values.inicio}
                onChange={formik.handleChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div>
              <label className="block text-gray-700">Fin</label>
              <input
                type="date"
                name="fin"
                value={formik.values.fin}
                onChange={formik.handleChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
          </div>

          {/* Third row: hora_inicio and hora_fin */}
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-gray-700">Hora inicio</label>
              <input
                type="time"
                name="hora_inicio"
                value={formik.values.hora_inicio}
                onChange={formik.handleChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div>
              <label className="block text-gray-700">Hora fin</label>
              <input
                type="time"
                name="hora_fin"
                value={formik.values.hora_fin}
                onChange={formik.handleChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
          </div>

          {/* Fourth row: Asunto */}
          <div className="mb-4">
            <label className="block text-gray-700">Asunto</label>
            <input
              type="text"
              name="asunto"
              value={formik.values.asunto}
              onChange={formik.handleChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>

          {/* Fifth row: Descripcion */}
          <div className="mb-4">
            <label className="block text-gray-700">Descripcion</label>
            <textarea
              name="descripcion"
              value={formik.values.descripcion}
              onChange={formik.handleChange}
              className="w-full p-2 border border-gray-300 rounded"
              rows="4"
            />
          </div>

          {/* Sixth row: Archivos */}
          <div className="mb-4">
            <MultipleFileUpload
              name="archivos"
              value={formik.values.archivos}
              onChange={(files) => formik.setFieldValue("archivos", files)}
            />
          </div>

          {/* Submit button */}
          <button
            type="submit"
            className="w-full py-2 bg-sky-500 text-white rounded hover:bg-sky-600"
          >
            {id ? "Editar Permiso" : "Solicitar Permiso"}
          </button>
        </form>
      )}
      {permisos_loading && tiposPermiso_loading && <LoadingSpinButton />}
    </div>
  );
}
