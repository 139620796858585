import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { kpiActions } from "../handlers/redux";
import { Card } from "src/modules/common/components/Card";
import SelectModal from "src/modules/common/components/SelectModal";
import {
  ArrowLeftShort,
  XCircle,
  PencilSquare,
  CardChecklist,
  FileEarmarkArrowDownFill,
  ClockHistory,
  XLg,
} from "react-bootstrap-icons";
import { commonActions } from "src/modules/common/handler/redux";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import KpiForm from "./KpiForm";
import AnotacionForm from "./AnotacionForm";
import { formatNumber } from "src/utils/helpers";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { getToken } from "src/modules/common/handler/helpers";
import axios from "axios";
import Modal from "src/modules/common/components/Modal";
import KPIHistorial from "../components/KPIHistorial";
import { colaboradorActions } from "src/modules/colaborador/handlers/redux";
import DataTable from "src/modules/common/components/DataTable";
import dayjs from "dayjs";
import SearchInput from "src/modules/common/components/SearchInput";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";
import ReactSelect from "react-select";

const initialKPI = {
  id: null,
  nombre: "",
  descripcion: "",
  periodo: null,
  metrica: null,
  meta: null,
  peso: 0.0,
  rangos: [],
};

function ColaboradorDetailPage() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //
  const kpisNoVigentes = useSelector(
    (state) => state.kpi.colaboradorKpisNoVigentes
  );
  const kpisNoVigentesChecked = useSelector(
    (state) => state.kpi.colaboradorKpisNoVigentes_checked
  );
  const kpisNoVigentes_count = useSelector(
    (state) => state.kpi.colaboradorKpisNoVigentes_count
  );
  const kpisNoVigentes_loading = useSelector(
    (state) => state.kpi.colaboradorKpisNoVigentes_loading
  );
  const kpisNoVigentes_filter = useSelector(
    (state) => state.kpi.colaboradorKpisNoVigentes_filter
  );
  //
  const URL_BASE = process.env.REACT_APP_URL || "http://localhost:8000";
  const [modalContent, setModalContent] = useState("kpi");
  const { id: colaboradorId } = params;
  const empresa = useSelector((state) => state.colaborador.empresa);
  const { deshabilitar_edicion_kpis = false } = empresa;
  const colaborador = useSelector((state) => state.kpi.subordinado);
  const [isEditing, setIsEditing] = useState(false);
  const [kpisData, setKpisData] = useState([]);
  const [kpisArchivados, setKpisArchivados] = useState([]);
  const [kpiForm, setKpiForm] = useState(initialKPI);
  const [kpiFormIndex, setKpiFormIndex] = useState(null);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [kpiHistory, setKpiHistory] = useState([]);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const kpisContainerRef = useRef(null);
  const topRef = useRef(null);

  const scrollToComponent = () => {
    kpisContainerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const onVerHistorial = (kpi) => () => {
    setKpiHistory(kpi);
    setShowHistoryModal(true);
  };
  const onCloseHistoryModal = () => {
    setKpiHistory(null);
    setShowHistoryModal(false);
  };
  //
  useEffect(() => {
    dispatch(kpiActions.subordinado({ id: colaboradorId }));
    dispatch(kpiActions.getColaboradorKpisNoVigentes({ id: colaboradorId }));
    dispatch(commonActions.setSelectModal({ open: false }));
    if (!empresa.id) {
      dispatch(colaboradorActions.getEmpresa());
    }
    topRef?.current?.scrollIntoView({ behavior: "auto" });
  }, []);

  useEffect(() => {
    setKpisData(colaborador.kpis_colaborador);
    setKpisArchivados([]);
  }, [colaborador?.kpis_colaborador?.length]);

  useEffect(() => {
    const kpisActualizados = kpisData?.filter((kpi) => kpi.vigente === "false");
    if (kpisActualizados?.length > 0) {
      setKpisArchivados((prevArchivados) => [
        ...prevArchivados,
        ...kpisActualizados,
      ]);
      setKpisData(kpisData.filter((kpi) => kpi.vigente !== "false"));
    }
  }, [kpisData]);

  useEffect(() => {
    const kpisActualizados = kpisArchivados.filter(
      (kpi) => kpi.vigente === true
    );
    if (kpisActualizados.length > 0) {
      setKpisData((prevData) => [...prevData, ...kpisActualizados]);
      setKpisArchivados((prevArchivados) =>
        prevArchivados.filter((kpi) => kpi.vigente === "false")
      );
    }
  }, [kpisArchivados]);

  const goBack = () => {
    navigate(`/kpis`);
  };

  const getModalContent = () => {
    if (modalContent === "kpi")
      return (
        <KpiForm
          kpiColaborador={kpiForm}
          onSave={agregarKpi(kpiFormIndex)}
        ></KpiForm>
      );
    if (modalContent === "anotacion")
      return (
        <AnotacionForm
          kpiColaborador={kpiForm}
          onSave={modificarAnotaciones(kpiFormIndex)}
        ></AnotacionForm>
      );
  };

  const getModalTitle = () => {
    if (modalContent === "kpi")
      return `${!!kpiFormIndex ? "Editar KPI" : "Agregar KPI"}`;
    if (modalContent === "anotacion") return "Anotaciones para este KPI";
  };

  const addNewKpi = () => {
    setModalContent("kpi");
    setKpiFormIndex(null);
    setKpiForm(initialKPI);
    dispatch(commonActions.setSelectModal({ open: true }));
  };

  const onEditar = (kpi, kpiIndex) => (e) => {
    e.stopPropagation();
    setKpiFormIndex(kpiIndex);
    setKpiForm(kpi);
    setModalContent("kpi");
    dispatch(commonActions.setSelectModal({ open: true }));
  };
  const onAddAnotacion = (kpi, kpiIndex) => (e) => {
    e.stopPropagation();
    setKpiFormIndex(kpiIndex);
    setKpiForm(kpi);
    setModalContent("anotacion");
    dispatch(commonActions.setSelectModal({ open: true }));
  };
  const showKpiMeta = (kpi) => {
    return kpi.meta
      ? `${formatNumber(kpi.meta)}`
      : kpi.rangos.length > 0
      ? "Por escala"
      : "Sin definir";
  };

  const agregarKpi = (index) => (kpi) => {
    let kpisDataCopy = JSON.parse(JSON.stringify(kpisData));
    if (parseInt(kpi.metrica || 0) === 3 || parseInt(kpi.metrica || 0) === 4) {
      kpi.meta = 0;
    }
    //
    if (!!index || index === 0) {
      kpisDataCopy[index] = kpi;
    } else {
      kpisDataCopy.push({ ...kpi });
    }
    setKpisData(kpisDataCopy);
    setKpiForm(initialKPI);
    dispatch(commonActions.setSelectModal({ open: false }));
    SuccessToast({
      message: !!index ? "KPI editado" : "KPI agregado al listado",
    });
  };

  const modificarAnotaciones = (index) => (anotaciones) => {
    let kpisDataCopy = JSON.parse(JSON.stringify(kpisData));
    let kpiCopy = kpisDataCopy[index];
    //
    kpiCopy.anotaciones = anotaciones;
    kpisDataCopy[index] = kpiCopy;
    //
    setKpisData(kpisDataCopy);
  };

  const onCancel = () => {
    setKpisData(colaborador?.kpis_colaborador);
    setIsEditing(false);
    undoRestoreKpis();
  };

  const onDeleteKPI = (id) => () => {
    let deseaEliminar = true;
    deseaEliminar = window.confirm("¿Está seguro de eliminar este KPI?");
    if (!!deseaEliminar) {
      const newKPIS = kpisData.filter((kpi, kidx) => kidx !== id);
      setKpisData(newKPIS);
    }
  };

  const onChangePeso = (index) => (e) => {
    const value = Number(e.target.value);
    if (value < 0 || value > 100) {
      return;
    }
    let kpisDataCopy = JSON.parse(JSON.stringify(kpisData));
    kpisDataCopy[index].peso = value;
    setKpisData(kpisDataCopy);
  };

  const onSave = () => {
    let sumaPesos = 0;
    kpisData.forEach((kpi, kidx) => (sumaPesos += kpi.peso));
    if (sumaPesos !== 0 && sumaPesos !== 100) {
      ErrorToast({ message: "La suma de los pesos debe ser de 100%" });
      return;
    }
    //
    const params = {
      id: colaboradorId,
      kpis: kpisData,
      archivados: kpisArchivados.map((kpi, i) => kpi.id),
    };
    dispatch(kpiActions.colaboradorUpdateKpis(params));
    //
    setIsEditing(false);
  };

  const restoreKpi = (kpi) => {
    const kpisDataCopy = JSON.parse(JSON.stringify(kpisData));
    kpisDataCopy.push({ ...kpi, aRestaurar: true });
    setKpisData(kpisDataCopy);
    //
    let kpisNoVigentesCopy = JSON.parse(JSON.stringify(kpisNoVigentes));
    kpisNoVigentesCopy = kpisNoVigentesCopy.filter(
      (kpinv, kidx) => kpinv.id !== kpi.id
    );
    const payload = {
      results: kpisNoVigentesCopy,
      count: kpisNoVigentesCopy.length,
    };
    dispatch(kpiActions.setColaboradorKpisNoVigentesSimple(payload));
    //
    setIsEditing(true);
    scrollToComponent();
  };

  const restoreKpis = (kpisToRestore) => {
    const kpisDataCopy = JSON.parse(JSON.stringify(kpisData));
    kpisToRestore.forEach((kpi) => {
      kpisDataCopy.push({ ...kpi, aRestaurar: true });
    });
    setKpisData(kpisDataCopy);
    let kpisNoVigentesCopy = JSON.parse(JSON.stringify(kpisNoVigentes));
    kpisNoVigentesCopy = kpisNoVigentesCopy.filter(
      (kpinv) => !kpisToRestore.some((kpi) => kpinv.id === kpi.id)
    );
    const payload = {
      results: kpisNoVigentesCopy,
      count: kpisNoVigentesCopy.length,
    };
    dispatch(kpiActions.setColaboradorKpisNoVigentesSimple(payload));
    setIsEditing(true);
    scrollToComponent();
  };

  const undoRestoreKpis = () => {
    let kpisDataCopy = JSON.parse(JSON.stringify(kpisData));
    let kpisNoVigentesCopy = JSON.parse(JSON.stringify(kpisNoVigentes));
    //
    kpisDataCopy = kpisDataCopy.filter((kpi) => {
      if (kpi.aRestaurar) {
        const { aRestaurar, ...kpiWithoutARestaurar } = kpi;
        kpisNoVigentesCopy.push(kpiWithoutARestaurar);
        return false;
      }
      return true;
    });
    //
    setKpisData(kpisDataCopy);
    //
    const payload = {
      results: kpisNoVigentesCopy,
      count: kpisNoVigentesCopy.length,
    };
    dispatch(kpiActions.setColaboradorKpisNoVigentesSimple(payload));
    //
    setIsEditing(false);
    scrollToComponent();
  };

  const datatableConfig = {
    headers: [
      {
        title: "Lista de KPIs",
        contentClass: "px-2 py-4",
        accessor: "nombre",
      },
      {
        title: "Métrica",
        accessor: "metrica_display",
      },
      {
        title: "Frecuencia",
        accessor: "periodo_display",
      },
      {
        title: "Creado",
        accessor: "creado_por.nombre",
        contentRenderer: (item) => (
          <div>
            {!!item.fecha_creacion
              ? dayjs(item.fecha_creacion.slice(0, 10)).format("DD/MM/YYYY")
              : "Sin registro"}
          </div>
        ),
      },
    ],
    rowActions: [
      {
        label: "Restaurar",
        action: (item) => {
          restoreKpi(item);
        },
      },
    ],
  };

  const downloadPDF = () => {
    const token = getToken();
    const URL_BASE =
      process.env.REACT_APP_API_URL || "http://localhost:8000/api/lider";
    setDownloadLoading(true);
    axios({
      url: `${URL_BASE}/kpi/subordinado/${colaboradorId}/exportar`,
      method: "POST",
      responseType: "blob", // Indica que la respuesta será un archivo binario
      headers: {
        Authorization: `Bearer ${token}`, // Agregamos la cabecera de autorización con el token Bearer
      },
    })
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `colaborador-${colaboradorId}-kpis.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setDownloadLoading(false);
      })
      .catch((error) => {
        console.error("Error al descargar el archivo PDF:", error);
        setDownloadLoading(false);
      });
  };

  const discardChanges = () => {
    let deseaSalir = window.confirm("¿Desea salir sin guardar los cambios?");
    return deseaSalir;
  };

  // DATATABLE UTILS
  const [Page, setPage] = useState(1);
  const [PageSize, setPageSize] = useState(10);
  const [outerPageSize, setOuterPageSize] = useState(50);

  const onSearchKPINoVigente = (event) => {
    dispatch(
      kpiActions.setColaboradorKpisNoVigentesFilter({
        ...kpisNoVigentes_filter,
        id: colaboradorId,
        nombre: event.target.value,
      })
    );
  };

  const onChangePageSize = (e) => {
    let value = e.target.value;
    //
    dispatch(
      kpiActions.setColaboradorKpisNoVigentesFilter({
        ...kpisNoVigentes_filter,
        id: colaboradorId,
        pagination: {
          pageSize: parseInt(value),
          page: 1,
        },
      })
    );
    //
    setOuterPageSize(parseInt(value));
  };

  const actionOptions = [
    {
      value: "restaurar",
      label: "Restaurar",
      // action: () => {},
    },
  ];

  const onAction = (v) => {
    if (v.value === "restaurar") {
      if (kpisNoVigentesChecked.length < 1) {
        ErrorToast({ message: "Debe seleccionar al menos un KPI." });
        return;
      }
      const deseaRestaurar = window.confirm(
        "¿Está seguro de restaurar los KPIs seleccionados?"
      );
      if (deseaRestaurar) {
        restoreKpis(kpisNoVigentesChecked);
        dispatch(kpiActions.setKpiNoVigentesChecked([]));
      }
      return;
    }
  };

  const onPaginate = (page, pageSize) => {
    //
    dispatch(
      kpiActions.setColaboradorKpisNoVigentesFilter({
        ...kpisNoVigentes_filter,
        id: colaboradorId,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };

  const onCheckKpiNoVigente = (item) => (event) => {
    dispatch(
      kpiActions.checkKpiNoVigente({
        kpi: item,
        checked: event.target.checked,
      })
    );
  };

  const onCheckKpiNoVigenteAll = (event) => {
    const checked = event.target.checked;
    dispatch(kpiActions.setKpiNoVigentesChecked(checked ? kpisNoVigentes : []));
  };

  return (
    <>
      <div ref={topRef}></div>
      <div className="flex items-center gap-2 px-4 py-4 bg-white mb-4 border-b-2">
        <div
          className="bg-cyan-100 opacity-90 p-1 rounded-md hover:cursor-pointer"
          onClick={goBack}
        >
          <ArrowLeftShort size={32}></ArrowLeftShort>
        </div>
        <h5 className="mb-0 font-semibold">KPIs del Colaborador</h5>
      </div>
      {/*  */}
      <div className="flex gap-4 px-12">
        <Card className="flex-1 gap-3 p-4">
          <div className="flex gap-4">
            <div>
              {!colaborador.foto_empleado && (
                <div className="h-28 w-28 rounded-lg bg-zinc-200"></div>
              )}
              {!!colaborador.foto_empleado && (
                <div className="w-28 bg-slate-200 rounded-md">
                  <img
                    className="object-cover h-28 w-28 rounded-md"
                    src={colaborador.foto_empleado}
                    alt={`Foto Lider`}
                  />
                </div>
              )}
              <div className="mt-3">
                <div className="mb-2 font-bold">Colaborador</div>
                <div>{`${colaborador.nombre} ${colaborador.apellido}`}</div>
                <div>{colaborador.cargo?.nombre || "Sin especificar"}</div>
              </div>
            </div>
            <div>
              <div className="mb-2 font-bold">Jefe directo:</div>
              <div className="flex gap-2">
                {!colaborador.jefe_directo?.foto_empleado && (
                  <div className="h-12 w-12 rounded-lg bg-zinc-200"></div>
                )}
                {!!colaborador.jefe_directo?.foto_empleado && (
                  <div className="w-12 bg-slate-200 rounded-md">
                    <img
                      className="object-cover h-12 w-12 rounded-md"
                      src={colaborador.jefe_directo?.foto_empleado}
                      alt={`Foto Lider`}
                    />
                  </div>
                )}
                <div>
                  <div>
                    {colaborador.jefe_directo
                      ? `${colaborador.jefe_directo?.nombre} ${colaborador.jefe_directo?.apellido}`
                      : "Sin especificar"}
                  </div>
                  <div>
                    {colaborador.jefe_directo?.cargo?.nombre ||
                      "Sin especificar"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-xs text-gray-500 mt-3">
            Si usted no es el evaluador o presenta algún error, notificar a su
            Jefe Directo o Gerente de RRHH.
          </div>
        </Card>
      </div>
      <div className="flex flex-col gap-4 px-12 py-4" ref={kpisContainerRef}>
        <Card bgColor="bg-zinc-100" className="gap-4 p-4 bg-blue-4">
          <div className="flex justify-between">
            <div className="flex flex-row gap-2 items-center">
              <div className="flex flex-col flex-grow text-zinc-700">
                <div className="text-sm font-bold">KPIs Asignados</div>
                <div className="text-sm">
                  {kpisData?.length} KPIs vinculados
                </div>
              </div>
            </div>
            <div className="flex gap-4">
              <>
                {!downloadLoading && (
                  <button
                    onClick={downloadPDF}
                    className="bg-sky-500 rounded-md text-white p-2 flex items-center gap-2"
                  >
                    <FileEarmarkArrowDownFill size={18} />
                    <span className="text-sm">Exportar PDF</span>
                  </button>
                )}
                {!!downloadLoading && (
                  <button className="bg-sky-500 rounded-md text-white p-2 animate-pulse">
                    Generando informe...
                  </button>
                )}
              </>
              {!deshabilitar_edicion_kpis && (
                <>
                  {!isEditing && (
                    <PrimaryButton
                      type="button"
                      onClick={() => setIsEditing(true)}
                    >
                      {!!kpisData && kpisData?.length > 0
                        ? "Editar"
                        : "Agregar"}
                    </PrimaryButton>
                  )}
                  {!!isEditing && (
                    <div className="flex gap-2">
                      <PrimaryButton className="bg-black" onClick={addNewKpi}>
                        <span>Asignar nuevo KPI</span>
                      </PrimaryButton>
                      <PrimaryButton type="button" onClick={onSave}>
                        Guardar
                      </PrimaryButton>
                      <PrimaryButton className="bg-red-500" onClick={onCancel}>
                        Cancelar
                      </PrimaryButton>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-3">
            {kpisData?.length > 0 &&
              kpisData?.map((kpi, idx) => (
                <Card key={`kpi-${idx}`} className="gap-3 p-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <div className="text-md font-bold">{kpi.nombre}</div>
                      <div className="text-sm">
                        {kpi.descripcion || "Sin descripción"}
                      </div>
                    </div>
                    <div>
                      <div className="text-xs">Peso:</div>
                      <div className="text-sm">
                        <input
                          type="number"
                          disabled={!isEditing}
                          onChange={onChangePeso(idx)}
                          className="border rounded-md p-1 w-1/2"
                          value={kpi.peso || 0}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="">
                      <div className="text-xs">Tiempo:</div>
                      <div className="font-semibold">
                        {kpi.periodo_display || "Sin definir"}
                      </div>
                    </div>
                    <div className="">
                      <div className="text-xs">Tipo de metrica:</div>
                      <div className="font-semibold">{kpi.metrica_display}</div>
                    </div>
                    <div className="">
                      <div className="text-xs">Meta:</div>
                      <div className="flex flex-row gap-1 items-center">
                        <div className="font-semibold">{showKpiMeta(kpi)}</div>
                      </div>
                    </div>
                    <div className="">
                      <div className="text-xs">Creado por:</div>
                      <div className="inline-flex gap-2 items-center">
                        {!kpi.creado_por?.foto_empleado && (
                          <div className="w-[70px] rounded-lg bg-zinc-200"></div>
                        )}
                        {!!kpi.creado_por?.foto_empleado && (
                          <div className="w-[70px] bg-slate-200 rounded-md border">
                            <img
                              className="object-cover w-full rounded-md"
                              src={kpi.creado_por?.foto_empleado}
                              alt={`Foto Jefe de Lider`}
                            />
                          </div>
                        )}
                        <div className="font-semibold">
                          {kpi.creado_por
                            ? `${kpi.creado_por.nombre} ${kpi.creado_por.apellido}`
                            : "Sin registro"}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* buttons */}
                  {!!isEditing && (
                    <div className="flex gap-2 items-center justify-end">
                      <button title="Editar" onClick={onEditar(kpi, idx)}>
                        <PencilSquare
                          size={18}
                          color={"#0e9bed"}
                        ></PencilSquare>
                      </button>
                      <button
                        title="Anotaciones"
                        onClick={onAddAnotacion(kpi, idx)}
                      >
                        <CardChecklist
                          size={18}
                          color={"black"}
                        ></CardChecklist>
                      </button>
                      <button title="Eliminar" onClick={onDeleteKPI(idx)}>
                        <XCircle size={16} color={"red"}></XCircle>
                      </button>
                    </div>
                  )}
                  {!isEditing && (
                    <div className="flex mt-4 gap-2">
                      <button
                        onClick={onVerHistorial(kpi)}
                        className="text-sm text-sky-500 flex item-center gap-1"
                      >
                        <ClockHistory className="mt-1" />
                        <span>Ver historial</span>
                      </button>
                    </div>
                  )}
                </Card>
              ))}
          </div>
        </Card>
      </div>
      {/* ================= KPIS A SER ARCHIVADOS ================= */}
      {kpisArchivados.length > 0 && (
        <div className="fflex flex-col gap-4 px-12 py-4">
          <Card bgColor="bg-zinc-100" className="gap-4 p-4">
            <div className="flex flex-col flex-grow text-zinc-700">
              <div className="text-sm font-bold">KPIs No Vigentes</div>
              <div className="text-sm">{kpisArchivados?.length} KPIs</div>
            </div>
            {/*  */}
            <div className="grid grid-cols-2 gap-3">
              {kpisArchivados?.length > 0 &&
                kpisArchivados?.map((kpi, idx) => (
                  <Card key={`kpi-${idx}`} className="gap-3 p-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <div className="text-md font-bold">{kpi.nombre}</div>
                        <div className="text-sm">
                          {kpi.descripcion || "Sin descripción"}
                        </div>
                      </div>
                      <div>
                        <div className="text-xs">Peso:</div>
                        <div className="text-sm">
                          <input
                            type="number"
                            disabled={true}
                            onChange={onChangePeso(idx)}
                            className="border rounded-md p-1 w-1/2"
                            value={kpi.peso || 0}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <div className="">
                        <div className="text-xs">Tiempo:</div>
                        <div className="font-semibold">
                          {kpi.periodo_display || "Sin definir"}
                        </div>
                      </div>
                      <div className="">
                        <div className="text-xs">Tipo de metrica:</div>
                        <div className="font-semibold">
                          {kpi.metrica_display}
                        </div>
                      </div>
                      <div className="">
                        <div className="text-xs">Meta:</div>
                        <div className="flex flex-row gap-1 items-center">
                          <div className="font-semibold">
                            {showKpiMeta(kpi)}
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div className="text-xs">Creado por:</div>
                        <div className="inline-flex gap-2 items-center">
                          {!kpi.creado_por?.foto_empleado && (
                            <div className="w-[70px] rounded-lg bg-zinc-200"></div>
                          )}
                          {!!kpi.creado_por?.foto_empleado && (
                            <div className="w-[70px] bg-slate-200 rounded-md border">
                              <img
                                className="object-cover w-full rounded-md"
                                src={kpi.creado_por?.foto_empleado}
                                alt={`Foto Jefe de Lider`}
                              />
                            </div>
                          )}
                          <div className="font-semibold">
                            {kpi.creado_por
                              ? `${kpi.creado_por.nombre} ${kpi.creado_por.apellido}`
                              : "Sin registro"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                ))}
            </div>
          </Card>
        </div>
      )}
      {/* ================ HISTORICO DE KPIS ================ */}
      <div className="flex flex-col gap-4 px-12 py-4">
        <Card bgColor="bg-white" className="gap-4 p-4 bg-blue-4">
          <div className="grid grid-cols-1 md:grid-cols-12 mb-8 mt-4">
            <div className="col-span-1 md:col-span-12 border-b mb-6 mt-4 pb-2">
              <div className="font-bold text-lg">Historial de KPIs</div>
            </div>
            <div className="col-span-1 md:col-span-6 mb-4 flex gap-4">
              <SearchInput
                placeholder="Buscar kpi..."
                value={kpisNoVigentes_filter.nombre}
                onChange={onSearchKPINoVigente}
              />
              <MostrarPaginas
                containerClass={"w-1/2x"}
                onChangePageSize={onChangePageSize}
              />
              <ReactSelect
                className="w-52 h-10 bg-blue-200 inline-grid"
                value={{
                  value: "",
                  label: `(${kpisNoVigentesChecked.length}) seleccionados`,
                }}
                options={actionOptions}
                onChange={onAction}
              ></ReactSelect>
            </div>
            <div className="col-span-1 md:col-span-12">
              <DataTable
                containerClass={"my-8x"}
                data={kpisNoVigentes}
                loading={kpisNoVigentes_loading}
                config={datatableConfig}
                // pagination
                totalRecords={kpisNoVigentes_count}
                onPaginate={onPaginate}
                outerPageSize={PageSize}
                // check config
                checkable={true}
                onCheckAll={onCheckKpiNoVigenteAll}
                checkedItems={kpisNoVigentesChecked}
                onItemCheck={onCheckKpiNoVigente}
              />
            </div>
          </div>
        </Card>
      </div>
      {/*  */}
      <SelectModal
        className={"absolute top-auto"}
        title={getModalTitle()}
        content={getModalContent()}
        onClose={() => discardChanges()}
      ></SelectModal>
      <Modal isOpen={showHistoryModal}>
        <div className="rounded-md bg-white p-4">
          <div className="w-100 flex justify-between mb-4">
            <div className="font-semibold text-xl">Historial de KPI</div>
            <div onClick={onCloseHistoryModal}>
              <XLg />
            </div>
          </div>
          {/*  */}
          <KPIHistorial {...kpiHistory} />
        </div>
      </Modal>
    </>
  );
}

export default ColaboradorDetailPage;
