import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { evaluacionActions } from "../handlers/redux";
import FormulariosTable from "./FormulariosTable";
import { getEstadoLabel } from "../helpers/helpers";

// @estadoEvaluacion 1: En curso, 2: Finalizado -> Indica si la evaluación general sigue en curso o ya está finalizado.
function EvaluacionesTable({ estadoEvaluacion = 1 }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { idEvaluacion } = params;
  const evaluaciones = useSelector(
    (state) => state.evaluacion.evaluaciones_lider
  );
  const evaluaciones_loading = useSelector(
    (state) => state.evaluacion.evaluaciones_lider_loading
  );
  const evaluaciones_filter = useSelector(
    (state) => state.evaluacion.evaluaciones_lider_filter
  );
  const { nombre = "" } = evaluaciones_filter;
  const setOrdering =
    (field) =>
    (order = "") => {
      let { ordering = "" } = evaluaciones_filter;
      ////
      let orderingArray = ordering.split(",") || [];
      let emptyIndex = orderingArray.indexOf("");
      if (emptyIndex) {
        orderingArray.splice(emptyIndex, 1);
      }
      let fieldIndex = orderingArray.indexOf(field);
      fieldIndex =
        fieldIndex === -1 ? orderingArray.indexOf(`-${field}`) : fieldIndex;
      // si ya esta el campo, vemos si se modifica o elimina
      console.info("S==== setOrdering", ordering, orderingArray, fieldIndex);
      if (fieldIndex > 0) {
        orderingArray.splice(fieldIndex, 1);
        if (order) {
          field = order === "desc" ? `-${field}` : field;
          orderingArray[fieldIndex] = field;
        }
      } else {
        // si no está, lo agregamos
        field = order === "desc" ? `-${field}` : field;
        orderingArray.push(field);
        console.info("orderingArray 2", orderingArray);
      }
      ordering = orderingArray.join(",");
      //
      dispatch(
        evaluacionActions.setEvaluacionesLiderFilter({
          ...evaluaciones_filter,
          id: idEvaluacion,
          ordering: ordering,
        })
      );
    };
  const headers = [
    { text: "Colaborador", onOrder: false, onFilter: false },

    {
      text: "Relación",
      onOrder: setOrdering("relacion"),
    },
    {
      text: "Formulario",
      onOrder: setOrdering("configuracion__nombre"),
    },
    {
      text: "Puntaje",
      onOrder: setOrdering("puntaje_obtenido"),
    },
    // {
    //   text: "Estado",
    //   onOrder: setOrdering("estado"),
    // },
    { text: "Acción", onOrder: setOrdering("estado"), onFilter: false },
  ];

  const onChangeText = (event) => {
    dispatch(
      evaluacionActions.setEvaluacionesLiderFilter({
        id: idEvaluacion,
        nombre: event.target.value,
      })
    );
  };

  const goToEvaluar = (formulario) => (e) => {
    if (estadoEvaluacion === 1) {
      const idFormulario = formulario.id;
      const evaluarRoute = `/evaluaciones/${idEvaluacion}/formulario/${idFormulario}/evaluacion`;
      navigate(evaluarRoute);
    }
  };

  const renderActionButton = (formulario) => {
    if (estadoEvaluacion === 1) {
      const { estado } = formulario;
      const label = getEstadoLabel(estado);
      //
      if (estado === "Enviado") {
        return (
          <>
            <button
              onClick={(e) => {
                // e.stopPropagation();
              }}
              className="bg-sky-500 text-white py-1 px-2 border rounded-md text-sm"
            >
              Evaluar
            </button>
          </>
        );
      }
      return (
        <>
          <div className="py-1 px-2 bg-slate-200 border rounded-md border-black text-sm">
            {label}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="py-1 px-2 bg-slate-200 border rounded-md border-black text-sm">
            No disponible
          </div>
        </>
      );
    }
  };

  useEffect(() => {
    dispatch(evaluacionActions.getEvaluacionesLider({ id: idEvaluacion }));
  }, []);
  //
  return (
    <FormulariosTable
      data={evaluaciones}
      headers={headers}
      extraClass={"mt-8"}
      searchValue={nombre}
      onSearch={onChangeText}
      loading={evaluaciones_loading}
      onRowClick={goToEvaluar}
      renderActionButton={renderActionButton}
    ></FormulariosTable>
  );
}

export default EvaluacionesTable;
