import { createSlice } from "@reduxjs/toolkit";

const permisoSlice = createSlice({
  name: "permiso",
  initialState: {
    permisos: [],
    permisos_loading: false,
    permisos_filter: {
      search: "",
      pagination: { page: 1, pageSize: 50 },
    },
    permisos_count: 0,
    pendientes_count: 0,
    mostrar_solicitudes: false,
    //
    tiposPermiso: [],
    tiposPermiso_loading: false,
    //
    permiso: {},
    permiso_loading: false,
    permiso_error: null,
    //
    solicitudes_pendientes: [],
    solicitudes_pendientes_loading: false,
    solicitudes_pendientes_filter: {
      search: "",
      pagination: { page: 1, pageSize: 50 },
    },
    //
    solicitudes_historial: [],
    solicitudes_historial_loading: false,
    solicitudes_historial_filter: {
      search: "",
      pagination: { page: 1, pageSize: 50 },
    },
    //
    comentario: "",
    comentario_loading: false,
    comentario_error: null,
  },
  reducers: {
    setPendientesCount: (state, { payload = {} }) => {
      state.pendientes_count = payload;
    },
    setMostrarSolicitudes: (state, { payload = {} }) => {
      state.mostrar_solicitudes = payload;
    },
    // General actions for fetching permisos
    getPermisos: (state, { payload = {} }) => {
      state.permisos_loading = true;
      state.permisos_filter = payload;
      state.permiso_error = null;
    },
    setPermisos: (state, { payload = [] }) => {
      state.permisos = payload;
      state.permisos_loading = false;
    },
    setPermisosCount: (state, { payload = 0 }) => {
      state.permisos_count = payload;
    },

    // Actions for fetching a single permiso
    getPermiso: (state) => {
      state.permiso_loading = true;
    },
    setPermiso: (state, { payload = {} }) => {
      state.permiso = payload;
      state.permiso_loading = false;
    },

    // Actions for handling solicitudes pendientes
    getSolicitudesPendientes: (state, { payload = {} }) => {
      state.solicitudes_pendientes_loading = true;
      state.solicitudes_pendientes_filter = payload; // Store the current filter state
    },
    setSolicitudesPendientes: (state, { payload = [] }) => {
      state.solicitudes_pendientes = payload;
      state.solicitudes_pendientes_loading = false;
    },

    // Actions for handling solicitudes historial
    getSolicitudesHistorial: (state, { payload = {} }) => {
      state.solicitudes_historial_loading = true;
      state.solicitudes_historial_filter = payload; // Store the current filter state
    },
    setSolicitudesHistorial: (state, { payload = [] }) => {
      state.solicitudes_historial = payload;
      state.solicitudes_historial_loading = false;
    },

    // Actions for aceptar and rechazar permiso
    aceptarPermiso: (state, { payload = {} }) => {
      state.permiso_loading = true; // Optionally set loading state here if needed
    },
    rechazarPermiso: (state, { payload = {} }) => {
      state.permiso_loading = true; // Optionally set loading state here if needed
    },
    terminarProcesarPermiso: (state, { payload = {} }) => {
      const { id, estado } = payload;
      state.permiso.es_procesable = false;
      state.permiso_loading = false;
      const procesado = state.solicitudes_pendientes.filter(
        (item) => item.id == id
      )[0];
      state.solicitudes_pendientes = state.solicitudes_pendientes.filter(
        (item) => item.id !== id
      );
      state.solicitudes_historial = [
        ...state.solicitudes_historial,
        { ...procesado, estado: estado },
      ];
    },

    // Actions for creating/updating permiso
    createPermiso: (state) => {
      state.permiso_loading = true;
      state.permiso_error = null;
    },
    setPermisoCreated: (state, { payload }) => {
      state.permiso_loading = false;
      state.permisos.push(payload); // Add the new permiso to the list
    },
    updatePermiso: (state) => {
      state.permiso_loading = true;
      state.permiso_error = null;
    },
    setPermisoUpdated: (state, { payload }) => {
      state.permiso_loading = false;
      // Update the existing permiso in the list
      const index = state.permisos.findIndex(
        (permiso) => permiso.id === payload.id
      );
      if (index !== -1) {
        //@ts-ignore
        state.permisos[index] = payload;
      }
    },
    setPermisoError: (state, { payload }) => {
      state.permiso_loading = false;
      state.permiso_error = payload; // Store the error message
    },

    // Optionally, you could create actions to reset or clear state
    resetPermiso: (state) => {
      state.permiso = {};
      state.permiso_loading = false;
    },

    //
    getTiposPermiso: (state, { payload = {} }) => {
      state.tiposPermiso_loading = true;
    },
    setTiposPermiso: (state, { payload = [] }) => {
      state.tiposPermiso = payload;
      state.tiposPermiso_loading = false;
    },
    // Actions for adding comentario
    writeComentario: (state, { payload }) => {
      state.comentario = payload;
    },
    addComentario: (state) => {
      state.comentario_loading = true;
      state.comentario_error = null;
    },
    setComentarioAdded: (state, { payload }) => {
      state.comentario_loading = false;
      state.comentario = "";
      state.permiso.comentarios = [...state.permiso.comentarios, payload];
    },
    setComentarioError: (state, { payload }) => {
      state.comentario_loading = false;
      state.comentario_error = payload;
    },

    deleteComentario: (state) => {
      state.comentario_loading = true;
      state.comentario_error = null;
    },
    setComentarioDeleted: (state, { payload }) => {
      state.comentario_loading = false;
      // Remove the deleted comentario from the list
      state.permiso.comentarios = state.permiso.comentarios.filter(
        (comentario) => comentario.id !== payload
      );
    },
  },
});

export const permisoActions = permisoSlice.actions;
export const permisoReducer = permisoSlice.reducer;
