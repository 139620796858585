import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePagination, useSortBy, useTable } from "react-table";
import Header from "src/modules/app/components/Header";
import { Card } from "src/modules/common/components/Card";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import { TableComponent } from "src/modules/common/components/TableComponent";
import { SearchInput } from "../components/SearchInput";
import { permisoActions } from "../handlers/redux";
import { getStatusColors } from "../helpers/constants";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { ProfileImage } from "src/modules/common/components/ProfileImage";

export function PermisoSolicitudes() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  //
  const [searchHistorial, setSearchHistorial] = useState("");
  const [historialFilter, setHistorialFilter] = useState({});

  const {
    solicitudes_pendientes,
    pendientes_loading,
    pendientes_count,
    mostrar_solicitudes,
    //
    solicitudes_historial_loading,
    solicitudes_historial,
  } = useSelector((state) => state.permiso);

  // Fetch pending requests on component mount and whenever the search term changes
  useEffect(() => {
    dispatch(
      permisoActions.getSolicitudesPendientes({
        filter: { search: searchTerm },
      })
    );
  }, [dispatch, searchTerm]);

  useEffect(() => {
    dispatch(
      permisoActions.getSolicitudesHistorial({
        filter: { ...historialFilter, search: searchHistorial },
      })
    );
  }, [dispatch, historialFilter, searchHistorial]);

  const rechazarHandler = (permisoId) => (event) => {
    event.stopPropagation();
    const confirm = window.confirm("Está seguro de RECHAZAR la solicitud?");
    if (confirm) {
      dispatch(permisoActions.rechazarPermiso(permisoId));
    }
  };

  const aceptarHandler = (permisoId) => (event) => {
    event.stopPropagation();
    const confirm = window.confirm("Está seguro de ACEPTAR la solicitud?");
    if (confirm) {
      dispatch(permisoActions.aceptarPermiso(permisoId));
    }
  };

  // Define table columns
  const columns = useMemo(
    () => [
      {
        Header: "Solicitante",
        accessor: "solicitante",
        Cell: ({ row }) => {
          const { solicitante, cargo, solicitante_foto } = row.original;
          return (
            <div className="flex items-center gap-3">
              <div>
                {!solicitante_foto && (
                  <ProfileImage className="h-28 w-28 p-3"></ProfileImage>
                )}
                {!!solicitante_foto && (
                  <div className="w-16 bg-slate-200 rounded-md">
                    <img
                      className="object-cover h-16 w-16 rounded-md"
                      src={solicitante_foto}
                      alt={`Foto ${solicitante}`}
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-2">
                <div className="font-semibold">{solicitante}</div>
                <div>Cargo: {cargo}</div>
              </div>
            </div>
          );
        },
      },
      {
        Header: "Solicitud",
        accessor: "inicio",
        Cell: ({ row }) => {
          const [day, month] = dayjs(row.original.inicio)
            .format("D MMM")
            .split(" ");
          return (
            <div className="flex items-center gap-4 w-full md:w-1/2">
              <div
                style={{
                  backgroundColor: getStatusColors(row.original.estado)
                    .background,
                }}
                className="text-lg font-semibold text-center py-2 px-4 rounded-md"
              >
                <div>{day}</div>
                <div>{month}</div>
              </div>
              <div>
                <div className="font-semibold">{row.original.tipo}</div>
                <div>Asunto: {row.original.asunto}</div>
              </div>
            </div>
          );
        },
      },
      {
        Header: "Tiempo",
        accessor: "dias",
        Cell: ({ value }) => (
          <div>
            {value} {value > 1 ? "días" : "día"}
          </div>
        ),
      },
      {
        Header: "Acción",
        accessor: "estado",
        Cell: ({ row, value }) => (
          <div className="flex gap-2">
            <button
              onClick={rechazarHandler(row.original.id)}
              className="border-black rounded-md p-2"
            >
              Rechazar
            </button>
            <button
              onClick={aceptarHandler(row.original.id)}
              className="bg-sky-500 p-2 text-white rounded-md"
            >
              Aceptar
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: solicitudes_pendientes,
    },
    useSortBy,
    usePagination
  );

  const historialColumns = useMemo(
    () => [
      {
        Header: "Solicitante",
        accessor: "solicitante",
        Cell: ({ row }) => {
          const { solicitante, cargo, solicitante_foto } = row.original;
          return (
            <div className="flex items-center gap-3">
              <div>
                {!solicitante_foto && (
                  <ProfileImage className="h-28 w-28 p-3"></ProfileImage>
                )}
                {!!solicitante_foto && (
                  <div className="w-16 bg-slate-200 rounded-md">
                    <img
                      className="object-cover h-16 w-16 rounded-md"
                      src={solicitante_foto}
                      alt={`Foto ${solicitante}`}
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-2">
                <div className="font-semibold">{solicitante}</div>
                <div>Cargo: {cargo}</div>
              </div>
            </div>
          );
        },
      },
      { Header: "Tipo", accessor: "tipo" },
      { Header: "Asunto", accessor: "asunto" },
      {
        Header: "Tiempo",
        accessor: "dias",
        Cell: ({ value }) => (
          <div>
            {value} {value > 1 ? "días" : "día"}
          </div>
        ),
      },
      {
        Header: "Estado",
        accessor: "estado",
        Cell: ({ row, value }) => (
          <div
            style={{
              backgroundColor: getStatusColors(row.original.estado).background,
            }}
            className={`flex flex-col text-center rounded-md py-1 w-full md:w-1/2`}
          >
            <span
              style={{ color: getStatusColors(row.original.estado).text }}
              className="capitalize"
            >
              {row.original.estado}
            </span>
          </div>
        ),
      },
    ],
    []
  );

  // React Table para el historial de solicitudes
  const historialTableInstance = useTable(
    {
      columns: historialColumns,
      data: solicitudes_historial,
    },
    useSortBy,
    usePagination
  );

  return (
    <div>
      <Header titulo={"Permisos"} className={`bg-white`}>
        {/* @ts-ignore */}
        <div className="ml-4 flex items-center gap-2">
          <button
            onClick={() => navigate(`/permisos`)}
            className={`bg-slate-100 text-sm p-2 rounded-md `}
          >
            General
          </button>
          {!!mostrar_solicitudes && (
            <button
              onClick={() => navigate(`/permisos/solicitudes`)}
              className={`bg-slate-100 text-sm p-2 rounded-md border-black`}
            >
              Solicitudes{" "}
              {pendientes_count > 0 && (
                <span className="bg-red-500 py-1 px-1 rounded-full text-white text-sm">
                  {pendientes_count}
                </span>
              )}
            </button>
          )}
        </div>
      </Header>
      <div className="flex flex-col gap-4 px-12 py-4">
        <div className="flex justify-between">
          <h1 className="font-semibold text-lg">Solicitudes Pendientes</h1>
        </div>
        {/* Tabla de pendientes */}
        <div>
          <SearchInput
            value={searchTerm}
            onChange={setSearchTerm}
            className="mb-4 w-full md:w-1/2"
          />
          {pendientes_loading ? (
            <LoadingSpinButton message="Cargando..." />
          ) : (
            <Card className="px-4">
              <TableComponent
                tableInstance={tableInstance}
                onRowClick={(id) => navigate(`/permisos/detalle/${id}`)}
              />
            </Card>
          )}
        </div>

        {/* Tabla del historial de solicitudes procesadas */}
        <div className="mt-4">
          <h1 className="font-semibold text-lg mb-4">Historial de permisos</h1>
          <SearchInput
            value={searchHistorial}
            onChange={setSearchHistorial}
            className="mb-4 w-full md:w-1/2"
          />{" "}
          {/* Componente de búsqueda */}
          {solicitudes_historial_loading ? (
            <LoadingSpinButton message="Cargando..." />
          ) : (
            <Card>
              <TableComponent
                tableInstance={historialTableInstance}
                onRowClick={(id) => navigate(`/permisos/detalle/${id}`)}
              />
            </Card>
          )}
        </div>
      </div>
    </div>
  );
}

export default PermisoSolicitudes;
