import React, { useRef } from "react";
import { Upload } from "react-bootstrap-icons";

export function MultipleFileUpload({ name, value, onChange }) {
  const fileInputRef = useRef(null);
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    // Limit to 3 files
    if (files.length + value.length > 3) {
      window.alert("Solo puedes subir hasta3 archivos.");
      return;
    }

    // Combine existing files with new files
    const newFiles = [...value, ...files];
    onChange(newFiles);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = value.filter((_, i) => i !== index);
    onChange(updatedFiles);
  };

  const handleCustomButtonClick = () => {
    // Trigger the file input dialog
    fileInputRef.current.click();
  };

  return (
    <div className="mb-4 w-full">
      <label className="block text-gray-700">Archivos</label>
      <input
        type="file"
        name={name}
        multiple
        onChange={handleFileChange}
        ref={fileInputRef}
        className="hidden"
      />
      <button
        type="button"
        onClick={handleCustomButtonClick}
        className="flex items-center gap-2 mt-2 px-4 py-2 border-black rounded"
      >
        <Upload />
        Subir Archivos
      </button>
      <div className="mt-2 flex flex-wrap">
        {value.map((file, index) => (
          <div
            key={index}
            className="flex items-center mr-2 mt-2 bg-gray-200 rounded-full px-3 py-1"
          >
            <span className="text-gray-700">
              {file.name || file.archivo.split("/").slice(-1)}
            </span>
            <button
              type="button"
              onClick={() => handleRemoveFile(index)}
              className="ml-2 text-red-600"
            >
              &times;
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
