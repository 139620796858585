import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePagination, useSortBy, useTable } from "react-table"; // Importa React Table
import Header from "src/modules/app/components/Header";
import { Card } from "src/modules/common/components/Card";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import { TableComponent } from "src/modules/common/components/TableComponent";
import { SearchInput } from "../components/SearchInput";
import { permisoActions } from "../handlers/redux";
import { getStatusColors } from "../helpers/constants";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

export function PermisoGeneral() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Estados para filtros y ordenamiento
  const [solicitudesFilter, setSolicitudesFilter] = useState({});
  //
  const [searchSolicitudes, setSearchSolicitudes] = useState(""); // Estado para el input de búsqueda

  // Estados globales desde Redux
  const { permisos, permisos_loading, pendientes_count, mostrar_solicitudes } =
    useSelector((state) => state.permiso);

  useEffect(() => {
    dispatch(
      permisoActions.getPermisos({
        filter: { ...solicitudesFilter, search: searchSolicitudes },
      })
    );
  }, [dispatch, searchSolicitudes, solicitudesFilter]);

  // Configuración de columnas para React Table
  const solicitudesColumns = useMemo(
    () => [
      {
        Header: "Solicitudes enviadas",
        accessor: "tipo",
        Cell: ({ row }) => {
          const [day, month] = dayjs(row.original.inicio)
            .format("D MMM")
            .split(" ");
          return (
            <div className="flex items-center gap-4 w-full md:w-1/2">
              <div
                style={{
                  backgroundColor: getStatusColors(row.original.estado)
                    .background,
                }}
                className="text-lg font-semibold text-center py-2 px-4 rounded-md"
              >
                <div>{day}</div>
                <div>{month}</div>
              </div>
              <div>
                <div className="font-semibold">{row.original.tipo}</div>
                <div>Asunto: {row.original.asunto}</div>
              </div>
            </div>
          );
        },
      },
      {
        Header: "Tiempo",
        accessor: "dias",
        Cell: ({ value }) => (
          <div>
            {value} {value > 1 ? "días" : "día"}
          </div>
        ),
      },
      {
        Header: "Estado",
        accessor: "estado",
        Cell: ({ row, value }) => (
          <div
            style={{
              backgroundColor: getStatusColors(row.original.estado).background,
            }}
            className={`flex flex-col text-center rounded-md py-1 w-full md:w-1/2`}
          >
            <span
              style={{ color: getStatusColors(row.original.estado).text }}
              className="capitalize"
            >
              {row.original.estado}
            </span>
          </div>
        ),
      },
      // Añade más columnas según los datos
    ],
    []
  );

  // React Table para las solicitudes del usuario actual
  const solicitudesTableInstance = useTable(
    {
      columns: solicitudesColumns,
      data: permisos, // Los datos que vienen del estado de Redux
    },
    useSortBy, // Permite el ordenamiento
    usePagination // Paginación
  );

  return (
    <div>
      <Header titulo={"Permisos"} className={`bg-white`}>
        {/* @ts-ignore */}
        <div className="ml-4 flex items-center gap-2">
          <button
            onClick={() => navigate(`/permisos`)}
            className={`bg-slate-100 text-sm p-2 rounded-md border-black`}
          >
            General
          </button>
          {!!mostrar_solicitudes && (
            <button
              onClick={() => navigate(`/permisos/solicitudes`)}
              className={`bg-slate-100 text-sm p-2 rounded-md `}
            >
              Solicitudes{" "}
              {pendientes_count > 0 && (
                <span className="bg-red-500 p-1 rounded-full text-white text-sm">
                  {pendientes_count}
                </span>
              )}
            </button>
          )}
        </div>
      </Header>
      <div className="flex flex-col gap-4 px-12 py-4">
        <>
          <div className="flex justify-between">
            <h1 className="font-semibold text-lg">Solicitudes de permisos</h1>
            <button
              onClick={() => navigate(`/permisos/form/`)}
              className="bg-sky-500 text-white rounded-md p-2"
            >
              Solicitar Permiso
            </button>
          </div>

          {/* Tabla de solicitudes del usuario */}
          <div>
            <SearchInput
              value={searchSolicitudes}
              onChange={setSearchSolicitudes}
              className="mb-4 w-full md:w-1/2"
            />
            {/* Componente de búsqueda */}
            {permisos_loading ? (
              <LoadingSpinButton message="Cargando..." />
            ) : (
              <Card className="px-4">
                <TableComponent
                  tableInstance={solicitudesTableInstance}
                  onRowClick={(id) => navigate(`/permisos/detalle/${id}`)}
                />
              </Card>
            )}
          </div>
        </>
      </div>
    </div>
  );
}
