import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Header from "src/modules/app/components/Header";
import { Card } from "src/modules/common/components/Card";
import CircularProgressBar from "src/modules/common/components/CircularProgressBar";
import CircularProgressMediumBar from "src/modules/common/components/CircularProgressBarMedium";
import { commonActions } from "src/modules/common/handler/redux";
import CalibracionesTable from "../components/CalibracionesTable";
import ComentariosTable from "../components/ComentariosTable";
import DevolucionesTable from "../components/DevolucionesTable";
import EvaluacionesTable from "../components/EvaluacionesTable";
import FormulariosTable from "../components/FormulariosTable";
import { evaluacionActions } from "../handlers/redux";
import backButton from "src/modules/common/components/images/back-arrow.png";

function EvaluacionDetailPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { idEvaluacion } = params;
  const evaluacion = useSelector((state) => state.evaluacion.evaluacion);
  const evaluacion_loading = useSelector(
    (state) => state.evaluacion.evaluacion_loading
  );
  //
  useEffect(() => {
    dispatch(evaluacionActions.getEvaluacion({ id: idEvaluacion }));
  }, []);
  //
  const onBack = () => {
    return (
      <div
        onClick={() => navigate("/evaluaciones")}
        className={"h-full flex items-center pr-2 z-10 cursor-pointer mr-2"}
      >
        <img className="back" src={backButton} alt="Regresar" />
      </div>
    );
  };

  return (
    <>
      <Header
        titulo={"Evaluaciones"}
        subtitulo={"Este es el resumen del perfil seleccionado"}
        className={`bg-white`}
        LeftComponent={onBack}
      ></Header>
      <div className="flex flex-col gap-4 px-12 py-4">
        <div>Estado: {evaluacion.estado?.label}</div>
        {/* EVALUACIONES */}
        {!!evaluacion.cantidad_evaluaciones &&
          evaluacion.cantidad_evaluaciones !== "0/0" && (
            <Card className={"px-4"}>
              <div className="font-bold text-md border-b pb-2">
                Evaluaciones que debes realizar
              </div>
              <div>
                {!!evaluacion.cantidad_evaluaciones && (
                  <div className="flex flex-col gap-1 items-center">
                    {!!evaluacion_loading && (
                      <div className="bg-slate-200 rounded-full h-36 w-36 animate-pulse"></div>
                    )}
                    {!evaluacion_loading && (
                      <CircularProgressBar
                        label={evaluacion.cantidad_evaluaciones}
                        percent={evaluacion.evaluaciones_porcentaje}
                      ></CircularProgressBar>
                    )}
                    <div className="text-sm text-zinc-400 ">Evaluaciones</div>
                  </div>
                )}
                <EvaluacionesTable
                  estadoEvaluacion={evaluacion.estado?.value}
                />
              </div>
            </Card>
          )}
        {/* CALIBRACIONES */}
        {!!evaluacion.cantidad_calibraciones &&
          evaluacion.cantidad_calibraciones !== "0/0" && (
            <Card>
              <div className="font-bold text-md border-b pb-2">
                Calibraciones que debes realizar
              </div>
              <div>
                {!!evaluacion.cantidad_calibraciones && (
                  <div className="flex flex-col gap-1 items-center">
                    {!!evaluacion_loading && (
                      <div className="bg-slate-200 rounded-full h-36 w-36 animate-pulse"></div>
                    )}
                    {!evaluacion_loading && (
                      <CircularProgressBar
                        label={evaluacion.cantidad_calibraciones}
                        percent={evaluacion.calibraciones_porcentaje}
                      ></CircularProgressBar>
                    )}
                    <div className="text-sm text-zinc-400 ">Calibraciones</div>
                  </div>
                )}
                <CalibracionesTable></CalibracionesTable>
              </div>
            </Card>
          )}
        {/* DEVOLUCIONES */}
        {!!evaluacion.cantidad_devoluciones &&
          evaluacion.cantidad_devoluciones !== "0/0" && (
            <Card>
              <div className="font-bold text-md border-b pb-2">
                Devoluciones que debes realizar
              </div>
              <div>
                {!!evaluacion.cantidad_devoluciones && (
                  <div className="flex flex-col gap-1 items-center">
                    {!!evaluacion_loading && (
                      <div className="bg-slate-200 rounded-full h-36 w-36 animate-pulse"></div>
                    )}
                    {!evaluacion_loading && (
                      <CircularProgressBar
                        label={evaluacion.cantidad_devoluciones}
                        percent={evaluacion.devoluciones_porcentaje}
                      ></CircularProgressBar>
                    )}
                    <div className="text-sm text-zinc-400 ">Devoluciones</div>
                  </div>
                )}
                <DevolucionesTable></DevolucionesTable>
              </div>
            </Card>
          )}
        {/* COMENTARIOS */}
        {!!evaluacion.cantidad_comentarios &&
          evaluacion.cantidad_comentarios !== "0/0" && (
            <Card>
              <div className="font-bold text-md border-b pb-2">
                Comentarios que debes realizar
              </div>
              <div>
                {!!evaluacion.cantidad_comentarios && (
                  <div className="flex flex-col gap-1 items-center">
                    {!!evaluacion_loading && (
                      <div className="bg-slate-200 rounded-full h-36 w-36 animate-pulse"></div>
                    )}
                    {!evaluacion_loading && (
                      <CircularProgressBar
                        label={evaluacion.cantidad_comentarios}
                        percent={evaluacion.comentarios_porcentaje}
                      ></CircularProgressBar>
                    )}
                    <div className="text-sm text-zinc-400 ">Comentarios</div>
                  </div>
                )}
                <ComentariosTable></ComentariosTable>
              </div>
            </Card>
          )}
      </div>
    </>
  );
}

export default EvaluacionDetailPage;
