export default function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const getUserFromCookie = () => {
  let user = {};
  user.colaborador_id = getCookie("colaborador_id").replaceAll('"', "");
  user.empresa_id = getCookie("empresa_id").replaceAll('"', "");
  user.nombre = getCookie("usuario_nombre").replaceAll('"', "");
  user.nombre_completo = getCookie("usuario_nombre_completo").replaceAll(
    '"',
    ""
  );
  user.foto_empleado = getCookie("foto_empleado").replaceAll('"', "");
  user.es_admin = parseInt(getCookie("es_admin")) === 1 ? true : false;
  //
  user.permiso_kpi = parseInt(getCookie("permiso_kpi")) === 1 ? true : false;
  user.permiso_objetivo =
    parseInt(getCookie("permiso_objetivo")) === 1 ? true : false;
  user.permiso_evaluacion =
    parseInt(getCookie("permiso_evaluacion")) === 1 ? true : false;
  user.permiso_plan_carrera =
    parseInt(getCookie("permiso_plan_carrera")) === 1 ? true : false;
  user.permiso_beneficio =
    parseInt(getCookie("permiso_beneficio")) === 1 ? true : false;
  user.permiso_feedback =
    parseInt(getCookie("permiso_feedback")) === 1 ? true : false;
  user.permiso_escala_salarial =
    parseInt(getCookie("permiso_escala_salarial")) === 1 ? true : false;
  user.permiso_permiso =
    parseInt(getCookie("permiso_permiso")) === 1 ? true : false;
  //
  return user;
};

export const getToken = () => {
  let token = getCookie("token");
  if (!token) {
    token = getCookie("kento");
  }
  return token;
};

export const deleteCookie = (name, path, domain = "localhost") => {
  if (getCookie(name)) {
    document.cookie =
      name +
      "=" +
      (path ? ";path=" + path : "") +
      (domain ? ";domain=" + domain : "") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
};

export const deleteUserFromCookie = (domain = "localhost") => {
  deleteCookie("usuario_nombre", "/", domain);
  deleteCookie("foto_empleado", "/", domain);
  deleteCookie("es_admin", "/", domain);
  deleteCookie("colaborador_id", "/", domain);
  deleteCookie("empresa_id", "/", domain);
  deleteCookie("usuario_nombre_completo", "/", domain);
  deleteCookie("es_admin_parcial", "/", domain);
};
