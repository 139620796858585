import React from "react";
import { PermisoDetail } from "../pages/PermisoDetail";
import { PermisoForm } from "../pages/PermisoForm";
import { PermisoGeneral } from "../pages/PermisoGeneral";
import { PermisoSolicitudes } from "../pages/PermisoSolicitudes";

export const permisoRoutes = [
  {
    path: "/permisos",
    element: <PermisoGeneral />,
  },
  {
    path: "/permisos/solicitudes",
    element: <PermisoSolicitudes />,
  },
  {
    path: "/permisos/detalle/:id",
    element: <PermisoDetail />,
  },
  {
    path: "/permisos/form",
    element: <PermisoForm />,
  },
  {
    path: "/permisos/form/:id",
    element: <PermisoForm />,
  },
];
