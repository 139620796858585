import { call, put, select, takeEvery } from "redux-saga/effects";
import { getToken } from "src/modules/common/handler/helpers";
import { permisoApi } from "./api";
import { permisoActions } from "./redux";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { push } from "@lagunovsky/redux-react-router";

// Get all permisos with pagination and search
function* getPermisosSaga({ payload }) {
  const token = getToken();
  const permisos_filter = yield select(
    (state) => state.permiso.permisos_filter
  );
  //
  try {
    const data = yield call(permisoApi(token).getPermisos, {
      ...permisos_filter,
      ...payload,
    });
    yield put(permisoActions.setPermisos(data.results));
    yield put(permisoActions.setPermisosCount(data.count));
    yield put(permisoActions.setPendientesCount(data.pendientes));
    yield put(permisoActions.setMostrarSolicitudes(data.mostrar_solicitudes));
  } catch (error) {
    console.log({ error });
    yield put(permisoActions.setPermisos([]));
    yield put(permisoActions.setPermisosCount(0));
    ErrorToast({ message: "Ocurrió un error." });
  }
}

// Get a single permiso
function* getPermisoSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(permisoApi(token).getPermiso, payload);
    yield put(permisoActions.setPermiso(data));
  } catch (error) {
    console.log({ error });
    yield put(permisoActions.setPermisoError("Error fetching permiso details"));
  }
}

// Get solicitudes pendientes with pagination and search
function* getSolicitudesPendientesSaga({ payload }) {
  const token = getToken();
  const solicitudes_pendientes_filter = yield select(
    (state) => state.permiso.solicitudes_pendientes_filter
  );
  try {
    const data = yield call(permisoApi(token).getSolicitudesPendientes, {
      ...solicitudes_pendientes_filter,
      ...payload,
    });
    yield put(permisoActions.setSolicitudesPendientes(data.results));
    yield put(permisoActions.setPendientesCount(data.pendientes));
    yield put(permisoActions.setMostrarSolicitudes(data.mostrar_solicitudes));
  } catch (error) {
    console.log({ error });
    yield put(permisoActions.setSolicitudesPendientes([]));
    ErrorToast({
      message: "Ocurrió un error al obtener solicitudes pendientes.",
    });
  }
}

// Get solicitudes historial with pagination and search
function* getSolicitudesHistorialSaga({ payload }) {
  const token = getToken();
  const solicitudes_historial_filter = yield select(
    (state) => state.permiso.solicitudes_historial_filter
  );

  try {
    const data = yield call(permisoApi(token).getSolicitudesHistorial, {
      ...solicitudes_historial_filter,
      ...payload,
    });
    yield put(permisoActions.setSolicitudesHistorial(data.results));
  } catch (error) {
    console.log({ error });
    yield put(permisoActions.setSolicitudesHistorial([]));
    ErrorToast({
      message: "Ocurrió un error al obtener el historial de solicitudes.",
    });
  }
}

// Create a new permiso
function* createPermisoSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(permisoApi(token).createPermiso, payload);
    yield put(permisoActions.setPermisoCreated(data));
    SuccessToast({ message: "Permiso creado exitosamente." });
    yield put(push("/permisos"));
  } catch (error) {
    console.log({ error });
    yield put(permisoActions.setPermisoError("Error creating permiso"));
  }
}

// Update an existing permiso
function* updatePermisoSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(
      permisoApi(token).updatePermiso,
      payload.id,
      payload.data
    );
    yield put(permisoActions.setPermisoUpdated(data));
    SuccessToast({ message: "Permiso actualizado exitosamente." });
    yield put(push("/permisos"));
  } catch (error) {
    console.log({ error });
    yield put(permisoActions.setPermisoError("Error updating permiso"));
  }
}

// Accept a permiso
function* aceptarPermisoSaga({ payload }) {
  const token = getToken();
  try {
    yield call(permisoApi(token).aceptarPermiso, payload);
    yield put(
      permisoActions.terminarProcesarPermiso({
        id: payload,
        estado: "aceptado",
      })
    );
    SuccessToast({ message: "Permiso aceptado exitosamente." });
    yield put(push("/permisos/solicitudes"));
  } catch (error) {
    console.log({ error });
    yield put(permisoActions.setPermisoError("Error accepting permiso"));
  }
}

// Reject a permiso
function* rechazarPermisoSaga({ payload }) {
  const token = getToken();
  try {
    yield call(permisoApi(token).rechazarPermiso, payload);
    yield put(
      permisoActions.terminarProcesarPermiso({
        id: payload,
        estado: "rechazado",
      })
    );
    yield put(push("/permisos/solicitudes"));
    SuccessToast({ message: "Permiso rechazado exitosamente." });
  } catch (error) {
    console.log({ error });
    yield put(permisoActions.setPermisoError("Error rejecting permiso"));
  }
}

function* getTiposPermisoSaga({ payload }) {
  const token = getToken();

  try {
    const data = yield call(permisoApi(token).getTiposPermiso, {});
    yield put(permisoActions.setTiposPermiso(data));
  } catch (error) {
    console.log({ error });
    ErrorToast({
      message: "Ocurrió un error al obtener los tipos de permiso.",
    });
  }
}

function* addComentarioSaga({ payload }) {
  console.log("addComentarioSaga payload", payload);
  const token = getToken();
  try {
    const data = yield call(
      permisoApi(token).addComentarPermiso,
      payload.permisoId,
      payload.comentario
    );
    yield put(permisoActions.setComentarioAdded(data));
    SuccessToast({ message: "Comentario agregado!" });
  } catch (error) {
    console.log({ error });
    yield put(permisoActions.setComentarioError("Error adding comentario"));
  }
}

function* deleteComentarioSaga({ payload }) {
  const { permisoId, comentarioId } = payload;
  const token = getToken();
  try {
    yield call(permisoApi(token).deleteComentario, permisoId, comentarioId);
    yield put(permisoActions.setComentarioDeleted(comentarioId));
    SuccessToast({ message: "Comentario eliminado exitosamente." });
  } catch (error) {
    console.log({ error });
    yield put(permisoActions.setComentarioError("Error deleting comentario"));
  }
}

// Root saga
export function* permisoSagas() {
  yield takeEvery(permisoActions.getPermisos.type, getPermisosSaga);
  yield takeEvery(permisoActions.getPermiso.type, getPermisoSaga);
  yield takeEvery(
    permisoActions.getSolicitudesPendientes.type,
    getSolicitudesPendientesSaga
  );
  yield takeEvery(
    permisoActions.getSolicitudesHistorial.type,
    getSolicitudesHistorialSaga
  );
  yield takeEvery(permisoActions.createPermiso.type, createPermisoSaga);
  yield takeEvery(permisoActions.updatePermiso.type, updatePermisoSaga);
  yield takeEvery(permisoActions.aceptarPermiso.type, aceptarPermisoSaga);
  yield takeEvery(permisoActions.rechazarPermiso.type, rechazarPermisoSaga);
  yield takeEvery(permisoActions.getTiposPermiso.type, getTiposPermisoSaga);
  yield takeEvery(permisoActions.addComentario.type, addComentarioSaga);
  yield takeEvery(permisoActions.deleteComentario.type, deleteComentarioSaga);
}
