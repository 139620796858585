import axios from "axios";
import React, { useEffect, useState } from "react";
import { ArrowLeftShort, CardChecklist, ClockHistory, FileEarmarkArrowDownFill, PencilSquare, XCircle, XLg } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { colaboradorActions } from "src/modules/colaborador/handlers/redux";
import { Card } from "src/modules/common/components/Card";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import Modal from "src/modules/common/components/Modal";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import SelectModal from "src/modules/common/components/SelectModal";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { WarningToast } from "src/modules/common/components/WarningToast";
import { getToken } from "src/modules/common/handler/helpers";
import { commonActions } from "src/modules/common/handler/redux";
import KPIHistorial from "src/modules/kpi/components/KPIHistorial";
import AnotacionForm from "src/modules/kpi/pages/AnotacionForm";
import KpiForm from "src/modules/kpi/pages/KpiForm";
import { formatNumber } from "src/utils/helpers";
import { objetivoCargoActions } from "../handlers/redux";

const initialObjetivo = {
  id: null,
  nombre: "",
  descripcion: "",
  periodo: null,
  metrica: null,
  meta: null,
  peso: 0.0,
  rangos: [],
};

export function ObjetivoCargoDetailPage() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const URL_BASE = process.env.REACT_APP_URL || "http://localhost:8000";
  const [modalContent, setModalContent] = useState("objetivo");
  const { id: colaboradorId } = params;
  const empresa = useSelector((state) => state.colaborador.empresa);
  const { deshabilitar_edicion_kpis = false } = empresa;
  const colaborador = useSelector((state) => state.objetivoCargo.subordinado);
  const [isEditing, setIsEditing] = useState(false);
  const [objetivosData, setObjetivosData] = useState([]);
  const [objetivoForm, setObjetivoForm] = useState(initialObjetivo);
  const [objetivoFormIndex, setObjetivoFormIndex] = useState(null);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [objetivoHistory, setObjetivoHistory] = useState([]);
  const [showHistoryModal, setShowHistoryModal] = useState(false);

  const onVerHistorial = (objetivo) => () => {
    setObjetivoHistory(objetivo);
    setShowHistoryModal(true);
  };
  const onCloseHistoryModal = () => {
    setObjetivoHistory(null);
    setShowHistoryModal(false);
  };
  //
  useEffect(() => {
    dispatch(objetivoCargoActions.getSubordinado({ id: colaboradorId }));
    dispatch(commonActions.setSelectModal({ open: false }));
    if (!empresa.id) {
      dispatch(colaboradorActions.getEmpresa());
    }
  }, []);

  useEffect(() => {
    setObjetivosData(colaborador.objetivos_cargo_colaborador);
  }, [colaborador?.objetivos_cargo_colaborador?.length]);

  const goBack = () => {
    navigate(`/objetivo-cargo`);
  };

  const addAnotacion = (index) => {
    setModalContent("anotacion");
    dispatch(commonActions.setSelectModal({ open: true }));
  };

  const getModalContent = () => {
    if (modalContent === "objetivo")
      return (
        <KpiForm
          kpiColaborador={objetivoForm}
          onSave={agregarObjetivo(objetivoFormIndex)}
        ></KpiForm>
      );
    if (modalContent === "anotacion")
      return (
        <AnotacionForm
          kpiColaborador={objetivoForm}
          onSave={modificarAnotaciones(objetivoFormIndex)}
        ></AnotacionForm>
      );
  };

  const getModalTitle = () => {
    if (modalContent === "objetivo")
      return `${!!objetivoFormIndex ? "Editar objetivo" : "Agregar objetivo"}`;
    if (modalContent === "anotacion") return "Anotaciones para este objetivo";
  };

  const addNewObjetivo = () => {
    setModalContent("objetivo");
    setObjetivoFormIndex(null);
    setObjetivoForm(initialObjetivo);
    dispatch(commonActions.setSelectModal({ open: true }));
  };

  const onEditar = (objetivo, objetivoIndex) => (e) => {
    e.stopPropagation();
    setObjetivoFormIndex(objetivoIndex);
    setObjetivoForm(objetivo);
    setModalContent("objetivo");
    dispatch(commonActions.setSelectModal({ open: true }));
  };
  const onAddAnotacion = (objetivo, objetivoIndex) => (e) => {
    e.stopPropagation();
    setObjetivoFormIndex(objetivoIndex);
    setObjetivoForm(objetivo);
    setModalContent("anotacion");
    dispatch(commonActions.setSelectModal({ open: true }));
  };
  const showObjetivoMeta = (objetivo) => {
    return objetivo.meta
      ? `${formatNumber(objetivo.meta)}`
      : objetivo.rangos.length > 0
      ? "Por escala"
      : "Sin definir";
  };

  const agregarObjetivo = (index) => (objetivo) => {
    let objetivosDataCopy = JSON.parse(JSON.stringify(objetivosData));
    if (parseInt(objetivo.metrica || 0) === 3 || parseInt(objetivo.metrica || 0) === 4) {
      objetivo.meta = 0;
    }
    //
    if (!!index || index === 0) {
      objetivosDataCopy[index] = objetivo;
    } else {
      objetivosDataCopy.push({ ...objetivo });
    }
    setObjetivosData(objetivosDataCopy);
    setObjetivoForm(initialObjetivo);
    dispatch(commonActions.setSelectModal({ open: false }));
    SuccessToast({
      message: !!index ? "Objetivo editado" : "Objetivo agregado al listado",
    });
  };

  const modificarAnotaciones = (index) => (anotaciones) => {
    let objetivosDataCopy = JSON.parse(JSON.stringify(objetivosData));
    let objetivoCopy = objetivosDataCopy[index];
    //
    objetivoCopy.anotaciones = anotaciones;
    objetivosDataCopy[index] = objetivoCopy;
    //
    setObjetivosData(objetivosDataCopy);
  };

  const onCancel = () => {
    setObjetivosData(colaborador?.objetivos_cargo_colaborador);
    setIsEditing(false);
  };

  const onDeleteObjetivo = (id) => () => {
    let deseaEliminar = true;
    deseaEliminar = window.confirm("¿Está seguro de eliminar este objetivo?");
    if (!!deseaEliminar) {
      const newObjetivos = objetivosData.filter((objetivo, kidx) => kidx !== id);
      setObjetivosData(newObjetivos);
    }
  };

  const onChangePeso = (index) => (e) => {
    const value = Number(e.target.value);
    if (value < 0 || value > 100) {
      return;
    }
    let objetivosDataCopy = JSON.parse(JSON.stringify(objetivosData));
    objetivosDataCopy[index].peso = value;
    setObjetivosData(objetivosDataCopy);
  };

  const onSave = () => {
    let sumaPesos = 0;
    objetivosData.forEach((objetivo, kidx) => (sumaPesos += objetivo.peso));
    if (sumaPesos != 0 && sumaPesos != 100) {
      ErrorToast({ message: "La suma de los pesos debe ser de 100%" });
      return;
    }
    //
    const params = { id: colaboradorId, objetivos: objetivosData };
    dispatch(objetivoCargoActions.colaboradorUpdateObjetivos(params));
    //
    setIsEditing(false);
  };

  const downloadPDF = () => {
    WarningToast({ message: "En desarrollo" });
    /*
    const token = getToken();
    const URL_BASE =
      process.env.REACT_APP_API_URL || "http://localhost:8000/api/lider";
    setDownloadLoading(true);
    axios({
      url: `${URL_BASE}/kpi/subordinado/${colaboradorId}/exportar`,
      method: "POST",
      responseType: "blob", // Indica que la respuesta será un archivo binario
      headers: {
        Authorization: `Bearer ${token}`, // Agregamos la cabecera de autorización con el token Bearer
      },
    })
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `colaborador-${colaboradorId}-kpis.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setDownloadLoading(false);
      })
      .catch((error) => {
        console.error("Error al descargar el archivo PDF:", error);
        setDownloadLoading(false);
      });
     */
  };

  const discardChanges = () => {
    let deseaSalir = window.confirm("¿Desea salir sin guardar los cambios?");
    return deseaSalir;
  };

  return (
    <>
      <div className="flex items-center gap-2 px-4 py-4 bg-white mb-4 border-b-2">
        <div
          className="bg-cyan-100 opacity-90 p-1 rounded-md hover:cursor-pointer"
          onClick={goBack}
        >
          <ArrowLeftShort size={32}></ArrowLeftShort>
        </div>
        <h5 className="mb-0 font-semibold">Objetivos por cargo del colaborador</h5>
      </div>
      {/*  */}
      <div className="flex gap-4 px-12">
        <Card className="flex-1 gap-3 p-4">
          <div className="flex gap-4">
            <div>
              {!colaborador.foto_empleado && (
                <div className="h-28 w-28 rounded-lg bg-zinc-200"></div>
              )}
              {!!colaborador.foto_empleado && (
                <div className="w-28 bg-slate-200 rounded-md">
                  <img
                    className="object-cover h-28 w-28 rounded-md"
                    src={colaborador.foto_empleado}
                    alt={`Foto Lider`}
                  />
                </div>
              )}
              <div className="mt-3">
                <div className="mb-2 font-bold">Colaborador</div>
                <div>{`${colaborador.nombre} ${colaborador.apellido}`}</div>
                <div>{colaborador.cargo?.nombre || "Sin especificar"}</div>
              </div>
            </div>
            <div>
              <div className="mb-2 font-bold">Jefe directo:</div>
              <div className="flex gap-2">
                {!colaborador.jefe_directo?.foto_empleado && (
                  <div className="h-12 w-12 rounded-lg bg-zinc-200"></div>
                )}
                {!!colaborador.jefe_directo?.foto_empleado && (
                  <div className="w-12 bg-slate-200 rounded-md">
                    <img
                      className="object-cover h-12 w-12 rounded-md"
                      src={colaborador.jefe_directo?.foto_empleado}
                      alt={`Foto Lider`}
                    />
                  </div>
                )}
                <div>
                  <div>
                    {colaborador.jefe_directo
                      ? `${colaborador.jefe_directo?.nombre} ${colaborador.jefe_directo?.apellido}`
                      : "Sin especificar"}
                  </div>
                  <div>
                    {colaborador.jefe_directo?.cargo?.nombre ||
                      "Sin especificar"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-xs text-gray-500 mt-3">
            Si usted no es el evaluador o presenta algún error, notificar a su
            Jefe Directo o Gerente de RRHH.
          </div>
        </Card>
      </div>
      <div className="flex flex-col gap-4 px-12 py-4">
        <Card bgColor="bg-zinc-100" className="gap-4 p-4 bg-blue-4">
          <div className="flex justify-between">
            <div className="flex flex-row gap-2 items-center">
              <div className="flex flex-col flex-grow text-zinc-700">
                <div className="text-sm font-bold">Objetivos por cargo asignados</div>
                <div className="text-sm">
                  {objetivosData?.length} objetivos vinculados
                </div>
              </div>
            </div>
            <div className="flex gap-4">
              <>
                {!downloadLoading && (
                  <button
                    onClick={downloadPDF}
                    className="bg-sky-500 rounded-md text-white p-2 flex items-center gap-2"
                  >
                    <FileEarmarkArrowDownFill size={18} />
                    <span className="text-sm">Exportar PDF</span>
                  </button>
                )}
                {!!downloadLoading && (
                  <button className="bg-sky-500 rounded-md text-white p-2 animate-pulse">
                    Generando informe...
                  </button>
                )}
              </>
              {!deshabilitar_edicion_kpis && (
                <>
                  {!isEditing && (
                    <PrimaryButton
                      type="button"
                      onClick={() => setIsEditing(true)}
                    >
                      {!!objetivosData && objetivosData?.length > 0
                        ? "Editar"
                        : "Agregar"}
                    </PrimaryButton>
                  )}
                  {!!isEditing && (
                    <div className="flex gap-2">
                      <PrimaryButton className="bg-black" onClick={addNewObjetivo}>
                        <span>Asignar nuevo objetivo</span>
                      </PrimaryButton>
                      <PrimaryButton type="button" onClick={onSave}>
                        Guardar
                      </PrimaryButton>
                      <PrimaryButton className="bg-red-500" onClick={onCancel}>
                        Cancelar
                      </PrimaryButton>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-3">
            {objetivosData?.length > 0 &&
              objetivosData?.map((objetivo, idx) => (
                <Card key={`objetivo-${idx}`} className="gap-3 p-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <div className="text-md font-bold">{objetivo.nombre}</div>
                      <div className="text-sm">
                        {objetivo.descripcion || "Sin descripción"}
                      </div>
                    </div>
                    <div>
                      <div className="text-xs">Peso:</div>
                      <div className="text-sm">
                        <input
                          type="number"
                          disabled={!isEditing}
                          onChange={onChangePeso(idx)}
                          className="border rounded-md p-1 w-1/2"
                          value={objetivo.peso || 0}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="">
                      <div className="text-xs">Tiempo:</div>
                      <div className="font-semibold">
                        {objetivo.periodo_display || "Sin definir"}
                      </div>
                    </div>
                    <div className="">
                      <div className="text-xs">Tipo de metrica:</div>
                      <div className="font-semibold">{objetivo.metrica_display}</div>
                    </div>
                    <div className="">
                      <div className="text-xs">Meta:</div>
                      <div className="flex flex-row gap-1 items-center">
                        <div className="font-semibold">{showObjetivoMeta(objetivo)}</div>
                      </div>
                    </div>
                    <div className="">
                      <div className="text-xs">Creado por:</div>
                      <div className="inline-flex gap-2 items-center">
                        {!objetivo.creado_por?.foto_empleado && (
                          <div className="w-[70px] rounded-lg bg-zinc-200"></div>
                        )}
                        {!!objetivo.creado_por?.foto_empleado && (
                          <div className="w-[70px] bg-slate-200 rounded-md border">
                            <img
                              className="object-cover w-full rounded-md"
                              src={objetivo.creado_por?.foto_empleado}
                              alt={`Foto Jefe de Lider`}
                            />
                          </div>
                        )}
                        <div className="font-semibold">
                          {objetivo.creado_por
                            ? `${objetivo.creado_por.nombre} ${objetivo.creado_por.apellido}`
                            : "Sin registro"}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* buttons */}
                  {!!isEditing && (
                    <div className="flex gap-2 items-center justify-end">
                      <button title="Editar" onClick={onEditar(objetivo, idx)}>
                        <PencilSquare
                          size={18}
                          color={"#0e9bed"}
                        ></PencilSquare>
                      </button>
                      <button
                        title="Anotaciones"
                        onClick={onAddAnotacion(objetivo, idx)}
                      >
                        <CardChecklist
                          size={18}
                          color={"black"}
                        ></CardChecklist>
                      </button>
                      <button title="Eliminar" onClick={onDeleteObjetivo(idx)}>
                        <XCircle size={16} color={"red"}></XCircle>
                      </button>
                    </div>
                  )}
                  {!isEditing && (
                    <div className="flex mt-4 gap-2">
                      <button
                        onClick={onVerHistorial(objetivo)}
                        className="text-sm text-sky-500 flex item-center gap-1"
                      >
                        <ClockHistory className="mt-1" />
                        <span>Ver historial</span>
                      </button>
                    </div>
                  )}
                </Card>
              ))}
          </div>
        </Card>
      </div>
      {/*  */}
      <SelectModal
        className={"absolute top-0"}
        title={getModalTitle()}
        content={getModalContent()}
        onClose={() => discardChanges()}
      ></SelectModal>
      <Modal isOpen={showHistoryModal}>
        <div className="rounded-md bg-white p-4">
          <div className="w-100 flex justify-between mb-4">
            <div className="font-semibold text-xl">Historial de objetivos por cargo</div>
            <div onClick={onCloseHistoryModal}>
              <XLg />
            </div>
          </div>
          {/*  */}
          <KPIHistorial {...objetivoHistory} />
        </div>
      </Modal>
    </>
  );
}
